class Players {
  constructor(players) {
    this.players = players;
    this.currentPlayer = null;
  }

  switchToPlayer(playerKey) {
    for (let key in this.players) {
      const player = this.players[key];

      if (key == playerKey) {
        player.show();

        this.currentPlayer = player;
      } else {
        player.pause();
        player.hide();
      }
    }
  }

  isPlaying() {
    for (let key in this.players) {
      if (!this.players[key].isPaused()) {
        return true;
      }
    }

    return false;
  }

  cue(playerKey, remoteId, mediaUrl, title, seconds, startPlayback) {
    this.switchToPlayer(playerKey);

    this.currentPlayer.cue(remoteId, mediaUrl, title, seconds, startPlayback);
  }

  link(playerKey, postId, seconds) {
    return this.players[playerKey].link(postId, seconds);
  }
}

export default Players;
