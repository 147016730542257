const SummaryBuilder = require("packs/search/summary_builder").default;
const HistoryManager = require("packs/search/history_manager").default;
const ResultsBuilder = require("packs/search/results_builder").default;
const PatreonTooltip = require("packs/search/patreon_tooltip").default;
const PatreonRss = require("packs/search/patreon_rss").default;
const UpsideDown = require("packs/search/upside_down").default;
const EasterEggs = require("packs/search/easter_eggs").default;

const GreenAudioPlayer = require("packs/green-audio-player/main").default;
const YoutubePlayer = require("packs/search/youtube_player").default;
const PatreonPlayer = require("packs/search/patreon_player").default;
const PrankcastPlayer = require("packs/search/prankcast_player").default;
const NotlaPlayer = require("packs/search/notla_player").default;
const Players = require("packs/search/players").default;

window.greet = loadInitialResults;

const summaryBuilder = new SummaryBuilder({
  summary: "#summary",
  patreonCheckbox: "#patreon-checkbox"
});

const patreonRss = new PatreonRss({
  patreonCheckbox: "#patreon-checkbox",
  patreonRssUrl: "#patreon-rss-url",
  rssStatus: "#patreon-rss-status",
  resetMetadataCallback: resetMetadataCallback,
  updatePatreonResults: updatePatreonResults
});

const youtubePlayer = new YoutubePlayer({
  playerId: "youtube-player",
  hiddenClass: "hidden"
});

const patreonPlayer = new PatreonPlayer({
  title: "#patreon-title",
  logo: "#patreon-logo",
  player: "#patreon-player",
  audioPlayer: "#patreon-audio-player",
  audioPlayerDummy: "#patreon-audio-player-dummy",
  overlayHint: "#patreon-audio-player-overlay-hint",
  hiddenClass: "hidden",
  patreonRss: patreonRss,
  formatTime: formatTime,
  GreenAudioPlayer: GreenAudioPlayer
});

const prankcastPlayer = new PrankcastPlayer({
  title: "#prankcast-title",
  logo: "#prankcast-logo",
  player: "#prankcast-player",
  audioPlayer: "#prankcast-audio-player",
  hiddenClass: "hidden",
  formatTime: formatTime,
  GreenAudioPlayer: GreenAudioPlayer
});

const notlaPlayer = new NotlaPlayer({
  title: "#notla-title",
  logo: "#notla-logo",
  player: "#notla-player",
  audioPlayer: "#notla-audio-player",
  hiddenClass: "hidden",
  formatTime: formatTime,
  GreenAudioPlayer: GreenAudioPlayer
});

const players = new Players({
  youtube: youtubePlayer,
  patreon: patreonPlayer,
  prankcast: prankcastPlayer,
  notla: notlaPlayer
});

const easterEggs = new EasterEggs({
  unhidePlayer: unhidePlayer,
  players: players
});

const historyManager = new HistoryManager({
  search: "#search",
  patreonCheckbox: "#patreon-checkbox",
  searchForm: "#search-form",
  playerWrapper: "#player-wrapper",
  loadVideoResults: loadVideoResults,
  summaryBuilder: summaryBuilder,
  formatTime: formatTime,
  easterEggs: easterEggs
});

const patreonTooltip = new PatreonTooltip({
  inputs: "#patreon-inputs",
  hover: "#patreon-inputs-hover",
  tooltip: "#patreon-tooltip",
  playerOverlay: "#player-wrapper-overlay",
  playerOverlayEnabledClass: "enabled",
  tooltipHoverClass: "hover",
});

const resultsBuilder = new ResultsBuilder({
  contentItems: "#content-items",
  contentItemTemplate: "#content-item-template",
  search: "#search",
  contentItemClass: "content-item",
  contentItemLinkClass: "content-item-link",
  contentItemLinkTextClass: "content-item-link-text",
  contentItemUploadDateClass: "content-item-upload-date",
  transcriptLocationsClass: "transcript-locations",
  transcriptLocationClass: "transcript-location",
  transcriptLocationReadyClass: "ready",
  transcriptLocationLoadingClass: "loading",
  transcriptLocationNewTabClass: "new-tab",
  transcriptLocationTimestampClass: "timestamp",
  transcriptLocationPreviewClass: "preview",
  transcriptLocationSelectedClass: "selected",
  players: players,
  patreonRss: patreonRss,
  historyManager: historyManager,
  formatTime: formatTime
});

const upsideDown = new UpsideDown({
  selectors: ["#shit-brad-says-logo", "#search-container", "#summary", "#player-wrapper", ".content-item"],
  upsideDownClass: "upside-down"
});

function formatTime(seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

function resetMetadataCallback() {
  if (patreonPlayer.isVisible()) {
    patreonPlayer.pause();
    resultsBuilder.replacePlayingItem();
  }
}

function updatePatreonResults() {
  resultsBuilder.updatePatreonLocationStatuses();
  patreonPlayer.updateEnabled();
}

patreonRss.updateRss();

function loadVideoResults(videoResults, initialLoad, contentType, remoteId, seconds) {
  resultsBuilder.addResults(videoResults, contentType, remoteId, seconds);

  if (!videoResults.length && initialLoad) {
    resetHomeView();

    youtubePlayer.pause();

    var searchedText = document.getElementById("search").value;

    if (searchedText) {
      summaryBuilder.resultsSummary(videoResults);
    } else {
      summaryBuilder.totalsSummary();
    }

    return;
  }

  showPlayer(videoResults, contentType, remoteId, seconds);
  unhidePlayer(videoResults.length);
  summaryBuilder.resultsSummary(videoResults);
}

function timestampToSeconds(timestamp) {
  if (timestamp && timestamp.match(/([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/)) {
    return new Date("1970-01-01T" + timestamp + "Z").getTime() / 1000;
  }
}

function loadInitialResults(videoResults, sums, patreon, selectedContentType, selectedRemoteId, selectedTimestamp) {
  summaryBuilder.sums = sums;
  upsideDown.flipIfAprilFools();

  if (location.pathname === "/" && patreonRss.enabled) {
    patreon = true;
  }

  document.getElementById("patreon-checkbox").checked = patreon;

  const selectedSeconds = timestampToSeconds(selectedTimestamp);

  historyManager.setHistory(videoResults, true, true, false, selectedContentType, selectedRemoteId, selectedSeconds);

  if (!document.getElementById("search").value) {
    summaryBuilder.totalsSummary();

    return;
  }

  if (videoResults.length) {
    window.onYouTubeIframeAPIReady = function() {
      showPlayer(videoResults, selectedContentType, selectedRemoteId, selectedSeconds);
    }

    unhidePlayer(videoResults.length);
    resultsBuilder.addResults(videoResults, selectedContentType, selectedRemoteId, selectedSeconds);
  }

  summaryBuilder.resultsSummary(videoResults);
}

function unhidePlayer(unhide) {
  if (!unhide) {
    return;
  }

  document.getElementById("outer-container").classList.remove("center-search");
  document.getElementById("player-wrapper").hidden = false;
}

function resetHomeView() {
  document.getElementById("outer-container").classList.add("center-search");
  document.getElementById("player-wrapper").hidden = true;
  document.getElementById("summary").innerHTML = "&nbsp;"
}

function showPlayer(results, selectedContentType, selectedRemoteId, selectedSeconds) {
  if (results.length == 0) {
    return;
  }

  let selectedResult = undefined;
  let seconds = undefined;

  if (selectedContentType && selectedRemoteId) {
    selectedResult = results.find(
      result => result.content_type == selectedContentType && result.remote_id == selectedRemoteId
    );

    if (selectedResult) {
      seconds = selectedSeconds;
    }
  }

  selectedResult ??= results[0];
  const remoteId = selectedResult ? selectedResult.remote_id : null;
  seconds ??= selectedResult ? selectedResult.timestamps[0].seconds : null;

  let youtubeRemoteId = null;
  let youtubeSeconds = null;

  if (selectedResult.content_type == "youtube") {
    youtubeRemoteId = remoteId;
    youtubeSeconds = seconds;
  }

  const youtubePlayerCreated = youtubePlayer.create(youtubeRemoteId, youtubeSeconds);

  if (players.isPlaying()) {
    return;
  }

  if (selectedResult.content_type == "youtube" && youtubePlayerCreated) {
    players.switchToPlayer("youtube");
  } else {
    players.cue(selectedResult.content_type, remoteId, selectedResult.media_url, selectedResult.title, seconds, false);
  }
}
