class PrankcastPlayer {
  constructor({ title, logo, player, audioPlayer, hiddenClass, formatTime, GreenAudioPlayer }) {
    this.title = document.querySelector(title);
    this.logo = document.querySelector(logo);
    this.player = document.querySelector(player);
    this.greenAudioPlayer = new GreenAudioPlayer(audioPlayer, { formatTime: formatTime, hideVolumeOnMobile: false });
    this.hiddenClass = hiddenClass;
    this.GreenAudioPlayer = GreenAudioPlayer;

    this.audio = this.greenAudioPlayer.player;
    this.greenAudioPlayer.player.volume = 1;
  }

  pause() {
    this.GreenAudioPlayer.pausePlayer(this.audio);
  }

  isPaused() {
    return this.audio.paused;
  }

  hide() {
    this.player.classList.add(this.hiddenClass);
  }

  show() {
    this.player.classList.remove(this.hiddenClass);
  }

  cue(remoteId, mediaUrl, title, seconds, startPlayback) {
    if (this.audio.src != mediaUrl) {
      this.audio.src = mediaUrl;

      const href = this.link(remoteId, null);

      this.title.innerHTML = title;

      this.title.href = href;
      this.logo.href = href;
    }

    this.audio.currentTime = seconds;

    if (startPlayback && this.isPaused()) {
      this.GreenAudioPlayer.playPlayer(this.audio);
    }
  }

  link(remoteId, seconds) {
    let link = "https://prankcast.com/phonelosers/posts/" + remoteId;

    if (seconds) {
      let quoteAt = new Date(seconds * 1000).toISOString().substr(11, 8);
      link += "#quote_at=" + quoteAt;
    }

    return link;
  }
}

export default PrankcastPlayer;
