class HistoryManager {
  constructor({
    search, patreonCheckbox, searchForm, playerWrapper, loadVideoResults, summaryBuilder, formatTime, easterEggs
  }) {
    this.search = document.querySelector(search);
    this.patreonCheckbox = document.querySelector(patreonCheckbox);
    this.searchForm = document.querySelector(searchForm);
    this.playerWrapper = document.querySelector(playerWrapper);
    this.loadVideoResults = loadVideoResults;
    this.summaryBuilder = summaryBuilder;
    this.formatTime = formatTime;
    this.easterEggs = easterEggs;

    this.siteName = "Shit Brad Says";
    this.currentResults = undefined;

    window.addEventListener("popstate", (event) => {
      this.setTitle(event.state.searchedText);

      this.currentResults = event.state.results;

      this.search.value = event.state.searchedText;
      this.patreonCheckbox.checked = event.state.patreon;

      this.loadVideoResults(
        event.state.results,
        event.state.initialLoad,
        event.state.contentType,
        event.state.remoteId,
        event.state.seconds
      );
    });

    this.patreonCheckbox.addEventListener("change", () => {
      if (this.playerWrapper.hidden) {
        this.replaceInitialHistory();
        this.summaryBuilder.totalsSummary();
      }
    });

    this.searchForm.addEventListener("ajax:beforeSend", (event) => {
      const easterEggTriggered = this.easterEggs.tryEasterEgg(this.search.value);

      if (easterEggTriggered) {
        event.preventDefault();
      }
    });

    this.searchForm.addEventListener("ajax:success", (event) => {
      const [_data, _status, xhr] = event.detail;
      const results = JSON.parse(xhr.response);

      this.setTitle();
      this.setHistory(results);
      this.loadVideoResults(results);
    });

    this.searchForm.addEventListener("ajax:error", () => {
      this.summaryBuilder.errorSummary();
    });
  }

  replaceInitialHistory() {
    this.setHistory([], true, true, true);
  }

  setHistory(results, initialLoad, replaceHistory, skipSearchedText, contentType, remoteId, seconds) {
    if (results) {
      this.currentResults = results;
    }

    const searchedText = skipSearchedText ? "" : this.search.value;
    const patreonCheckbox = this.patreonCheckbox.checked;

    let url = "/";

    if (searchedText) {
      url += "?search=" + encodeURIComponent(searchedText);
    }

    if (patreonCheckbox) {
      url += (searchedText ? "&" : "?") + "patreon=true";
    }

    if (contentType && remoteId) {
      url += "&content_type=" + contentType + "&remote_id=" + remoteId;

      if (seconds !== undefined) {
        url += "&timestamp=" + this.formatTime(seconds);
      }
    }

    const state = {
      initialLoad: initialLoad,
      searchedText: searchedText,
      patreon: patreonCheckbox,
      results: this.currentResults,
      contentType: contentType,
      remoteId: remoteId,
      seconds: seconds
    }

    if (replaceHistory) {
      history.replaceState(state, "", url);
    } else {
      history.pushState(state, "", url);
    }
  }

  setTitle(searchedText) {
    let title = this.siteName;

    if (searchedText === undefined) {
      searchedText = this.search.value;
    }

    if (searchedText) {
      title += ": " + searchedText;
    }

    document.title = title;
  }
}

export default HistoryManager;
