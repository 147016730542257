class PatreonPlayer {
  constructor({ title, logo, player, audioPlayer, audioPlayerDummy, overlayHint, hiddenClass, patreonRss, formatTime, GreenAudioPlayer }) {
    this.title = document.querySelector(title);
    this.logo = document.querySelector(logo);
    this.player = document.querySelector(player);
    this.greenAudioPlayer = new GreenAudioPlayer(audioPlayer, { formatTime: formatTime, hideVolumeOnMobile: false });
    this.audioPlayerDummy = document.querySelector(audioPlayerDummy);
    this.greenAudioPlayerDummy = new GreenAudioPlayer(audioPlayerDummy, { formatTime: formatTime, hideVolumeOnMobile: false });
    this.overlayHint = document.querySelector(overlayHint);
    this.hiddenClass = hiddenClass;
    this.patreonRss = patreonRss;
    this.formatTime = formatTime;
    this.GreenAudioPlayer = GreenAudioPlayer;

    this.audio = this.greenAudioPlayer.player;
    this.lastRemoteId = null;
    this.lastSeconds = null;
    this.startPlayback = false;

    this.greenAudioPlayer.player.volume = 1;

    this.overlayHints = {
      disabled: "Add your Patreon RSS link to use this player",
      loading: "Updating Patreon RSS data..."
    };

    this.audio.addEventListener("error", () => {
      this.disable();
      this.patreonRss.updateRss(true, false, false);
    });
  }

  pause() {
    this.GreenAudioPlayer.pausePlayer(this.audio);

    this.startPlayback = false;
  }

  play() {
    this.GreenAudioPlayer.playPlayer(this.audio);
  }

  isPaused() {
    return this.audio.paused;
  }

  isVisible() {
    return !this.player.classList.contains(this.hiddenClass);
  }

  hide() {
    this.player.classList.add(this.hiddenClass);
  }

  show() {
    this.player.classList.remove(this.hiddenClass);
  }

  hideDummy() {
    this.audioPlayerDummy.classList.add(this.hiddenClass);
  }

  showDummy() {
    this.audioPlayerDummy.classList.remove(this.hiddenClass);
  }

  cue(remoteId, _mediaUrl, title, seconds, startPlayback) {
    this.lastRemoteId = remoteId;
    this.lastSeconds = seconds;
    this.startPlayback = startPlayback;

    const mediaUrl = this.patreonRss.mediaUrlForRemoteId(remoteId);

    if (mediaUrl === null || this.audio.src != mediaUrl) {
      const href = this.link(remoteId, null);

      this.title.innerHTML = title;

      this.title.href = href;
      this.logo.href = href;
    }

    if (mediaUrl) {
      this.audio.src = mediaUrl;
      this.audio.currentTime = seconds;

      this.hideDummy();
    }

    if (startPlayback && this.isPaused()) {
      this.play();
    }
  }

  updateEnabled() {
    const mediaUrl = this.patreonRss.mediaUrlForRemoteId(this.lastRemoteId);

    mediaUrl ? this.enable(mediaUrl) : this.disable();
  }

  enable(mediaUrl) {
    this.audio.src = mediaUrl;
    this.audio.currentTime = this.lastSeconds;

    this.hideDummy();

    if (this.startPlayback) {
      this.play();
    }
  }

  disable() {
    if (this.audio.src) {
      this.lastSeconds = this.audio.currentTime;
    }

    this.updateOverlayHint();
    this.updateDummyLoading();
    this.showDummy();
  }

  updateOverlayHint() {
    const hint = this.patreonRss.enabled ? this.overlayHints.loading : this.overlayHints.disabled;

    this.overlayHint.innerHTML = hint;
  }

  updateDummyLoading() {
    if (this.patreonRss.enabled) {
      this.greenAudioPlayerDummy.loading.style = "visibility: visible";
      this.greenAudioPlayerDummy.playPauseBtn.style = "visibility: hidden";
    } else {
      this.greenAudioPlayerDummy.loading.style = "visibility: hidden";
      this.greenAudioPlayerDummy.playPauseBtn.style = "visibility: visible";
    }
  }

  link(remoteId, seconds) {
    let link = "https://www.patreon.com/posts/" + remoteId;

    if (seconds) {
      link += "#quote_at=" + this.formatTime(seconds);
    }

    return link;
  }
}

export default PatreonPlayer;
