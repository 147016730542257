class PatreonTooltip {
  constructor({ inputs, hover, tooltip, playerOverlay, playerOverlayEnabledClass, tooltipHoverClass }) {
    this.inputs = document.querySelector(inputs);
    this.hover = document.querySelector(hover);
    this.tooltip = document.querySelector(tooltip);
    this.playerOverlay = document.querySelector(playerOverlay);
    this.playerOverlayEnabledClass = playerOverlayEnabledClass;
    this.tooltipHoverClass = tooltipHoverClass;

    this.inputs.addEventListener("mouseenter", () => {
      this.tooltip.classList.add(this.tooltipHoverClass);
      this.playerOverlay.classList.add(this.playerOverlayEnabledClass);
    });

    document.addEventListener("mousemove", () => {
      if (!this.tooltip.classList.contains(this.tooltipHoverClass)) {
        return;
      }

      const outsidePatreonInputsHover = this.cursorOutsideElement(this.hover, event);
      const outsidePatreonTooltip = this.cursorOutsideElement(this.tooltip, event);

      if (outsidePatreonInputsHover && outsidePatreonTooltip) {
        this.tooltip.classList.remove(this.tooltipHoverClass);
        this.playerOverlay.classList.remove(this.playerOverlayEnabledClass);
      }
    });
  }

  cursorOutsideElement(element, event) {
    const rect = element.getBoundingClientRect();

    return (
      event.clientX < rect.left ||
      event.clientX > rect.right ||
      event.clientY < rect.top ||
      event.clientY > rect.bottom
    );
  }
}

export default PatreonTooltip;
