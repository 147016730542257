class EasterEggs {
  constructor({ unhidePlayer, players }) {
    this.unhidePlayer = unhidePlayer;
    this.players = players;

    this.nastyWords = ["bmln", "bmlnZw==", "bmlnZ2U=", "bmlnZ2Vy", "bmlnZ2Vycw==", "bmlnZ2E=", "bmlnZ2Fz"];
    this.nastyVideoId = "dQw4w9WgXcQ";

    this.easterEggs = {
      "bXkgZG9nIGF0ZSB0aGUgYmlzY290dGk=": "gtfretW34oU",
      "ZGV2b24gaXMgcG9vcGluIGFuZCBwZWVpbiBvdXQgdGhlcmU=": "EyoIgl-WQH8",
      "dGhlcmUgaXMgYSBkb2cgbWFkZSBvZiBsZXR0dWNlIG9uIHRoZSByb29m": "x48LxBFhdu4",
      [
        "VGhhdCBvbmUgc29uZyB0aGF0IEJyYWQgcGxheXMgdGhhdCdzIGxpa2UgQnVtIGJ1bS4gSHVtIGJ1bSBkZSBkZWVlIGRlIGRvb29vb29vLiBC" +
        "ZWUgYnVtIGJ1IGJ1bSBidW0gYmFhYWFhYWEuIEFuZCB0aGUgbHlyaWNzIGFyZSBhbGwgY3Jhenku"
      ]: "cUgiMulY42g",
      [
        "VGhhdCBvbmUgZXBpc29kZSB3aGVyZSBCcmFkIGNhbGxlZCBhIGd1eSB3aXRoIGEgZnVubnkgdm9pY2UuIEkgdGhpbmsgaGUgd2FzIGZyb20g" +
        "YSBwaGFybWFjeSBvciBhIHBpenphIHBsYWNlIG9yIHNvbWV0aGluZy4gSSBkb24ndCByZW1lbWJlciB3aGF0IHRoZSBwcmVtaXNlIG9mIHRo" +
        "ZSBjYWxsIHdhcywgYnV0IEJyYWQgc2FpZCBzb21ldGhpbmcgdG8gdGhlIGd1eSBhbmQgdGhlIGd1eSB3YXMgYWxsIHBpc3NlZCBvZmYuIEhl" +
        "IHNhaWQgc29tZXRoaW5nIGJhY2sgdG8gQnJhZCBpbiBoaXMgd2VpcmQgdm9pY2UuIEl0IHdhcyBzbyBmdW5ueS4gSSBjYW4ndCBldmVuIGRl" +
        "c2NyaWJlIGl0LiBXaGF0IHdhcyB0aGF0IGVwaXNvZGU/"
      ]: "akZT4TYB7wA",
      "ZG9nIGVnZ3MgdGhhdCBtaWdodCBiZSBmcm9tIGRlc3BpY2FibGUncyBidXR0": "ezSIF3hIf80"
    };
  }

  tryEasterEgg(search) {
    const videoId = this.isNasty(search) ? this.nastyVideoId : this.easterEggs[this.encode(search)];

    if (!videoId) {
      return false;
    }

    const youtubePlayerCreated = this.players.players.youtube.create(videoId, 0, true);

    if (youtubePlayerCreated) {
      this.players.switchToPlayer("youtube");
    } else {
      this.players.cue("youtube", videoId, null, null, 0, true);
    }

    this.unhidePlayer(true);

    return true;
  }

  isNasty(search) {
    const words = search.trim().toLowerCase().split(/ +/);

    for (let word of words) {
      const encodedWord = this.encode(word);

      if (this.nastyWords.includes(encodedWord)) {
        return true;
      }
    }

    return false;
  }

  encode(text) {
    try {
      return btoa(text.trim());
    } catch(e) {
      return "";
    }
  }
}

export default EasterEggs;
