class PatreonRss {
  constructor({ patreonCheckbox, patreonRssUrl, rssStatus, resetMetadataCallback, updatePatreonResults }) {
    this.patreonCheckbox = document.querySelector(patreonCheckbox);
    this.patreonRssUrl = document.querySelector(patreonRssUrl);
    this.rssStatus = document.querySelector(rssStatus);
    this.resetMetadataCallback = resetMetadataCallback;
    this.updatePatreonResults = updatePatreonResults;

    this.statuses = {
      reading: "Reading RSS feed...",
      active: "RSS feed active!",
      noLink: "No RSS link, you hobo!",
      notPlaLink: "Not a PLA RSS link!",
      connectionError: "Connection error!",
      unauthorized: "Unauthorized!",
      serverError: "Server error!"
    };

    this.storageKeys = {
      metadata: "patreonMetadata",
      rssUrl: "patreonRssUrl"
    };

    this.metadata = null;
    this.lastRequestId = 0;
    this.enabled = false;
    this.loading = false;

    this.patreonRssUrl.value = localStorage.getItem(this.storageKeys.rssUrl);

    this.patreonRssUrl.onkeydown = function(event) {
      return event.key != "Enter";
    };

    this.patreonRssUrl.addEventListener("input", () => {
      this.updateRss(true, true, true);
    });
  }

  resetMetadata() {
    localStorage.removeItem(this.storageKeys.metadata);

    this.metadata = null;
    this.enabled = false;

    this.resetMetadataCallback();
  }

  mediaUrlForRemoteId(remoteId) {
    if (!this.metadata) {
      return null;
    }

    return this.metadata.urls[remoteId];
  }

  updateRss(ignoreCurrentMetadata, ignoreCurrentRequests, updateCheckbox) {
    if (this.loading && !ignoreCurrentRequests) {
      return;
    }

    this.loading = true;

    this.lastRequestId += 1;
    const requestId = this.lastRequestId;

    const patreonRssUrl = this.patreonRssUrl.value;

    if (patreonRssUrl != localStorage.getItem(this.storageKeys.rssUrl)) {
      localStorage.setItem(this.storageKeys.rssUrl, patreonRssUrl);

      this.resetMetadata();
    }

    if (patreonRssUrl == "") {
      this.rssStatus.innerHTML = this.statuses.noLink;

      this.resetMetadata();
      this.updatePatreonResults();

      this.loading = false;

      return;
    }

    const authMatch = patreonRssUrl.match(/^https:\/\/www\.patreon\.com\/rss\/phonelosers\?auth=(.{32})$/, "$1");

    if (authMatch === null) {
      this.rssStatus.innerHTML = this.statuses.notPlaLink;

      this.resetMetadata();
      this.updatePatreonResults();

      this.loading = false;

      return;
    }

    this.enabled = true;
    this.metadata = JSON.parse(localStorage.getItem(this.storageKeys.metadata));

    if (this.metadata && !ignoreCurrentMetadata) {
      this.rssStatus.innerHTML = this.statuses.active;

      this.updatePatreonResults();

      this.loading = false;

      return;
    }

    this.updatePatreonResults();

    this.rssStatus.innerHTML = this.statuses.reading;

    const auth = authMatch[1];
    const requestUrl = "/patreon?auth=" + encodeURIComponent(auth);
    const xmlHttp = new XMLHttpRequest();

    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState == 4) {
        if (requestId != this.lastRequestId) {
          return;
        }

        switch (xmlHttp.status) {
          case 0:
            this.rssStatus.innerHTML = this.statuses.connectionError;

            break;

          case 200:
            localStorage.setItem(this.storageKeys.metadata, xmlHttp.responseText);
            this.metadata = JSON.parse(xmlHttp.responseText);
            this.rssStatus.innerHTML = this.statuses.active;

            if (updateCheckbox) {
              this.patreonCheckbox.checked = true;
            }

            break;

          case 403:
            this.rssStatus.innerHTML = this.statuses.unauthorized;

            this.resetMetadata();

            break;

          case 500:
            this.rssStatus.innerHTML = this.statuses.serverError;

            break;
        }

        this.updatePatreonResults();
        this.loading = false;
      }
    }

    xmlHttp.open("GET", requestUrl, true);
    xmlHttp.send(null);
  }
}

export default PatreonRss;
