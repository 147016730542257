class ResultsBuilder {
  constructor({
    contentItems, contentItemTemplate, search, contentItemClass, contentItemLinkClass, contentItemLinkTextClass,
    contentItemUploadDateClass, transcriptLocationsClass, transcriptLocationClass, transcriptLocationReadyClass,
    transcriptLocationLoadingClass, transcriptLocationNewTabClass, transcriptLocationTimestampClass,
    transcriptLocationPreviewClass, transcriptLocationSelectedClass, players, patreonRss, historyManager, formatTime
  }) {
    this.search = document.querySelector(search);

    this.contentItems = document.querySelector(contentItems);
    this.contentItemTemplate = document.querySelector(contentItemTemplate);

    this.contentItemClass = contentItemClass;
    this.contentItemLinkClass = contentItemLinkClass;
    this.contentItemLinkTextClass = contentItemLinkTextClass;
    this.contentItemUploadDateClass = contentItemUploadDateClass;

    this.transcriptLocationsClass = transcriptLocationsClass;
    this.transcriptLocationClass = transcriptLocationClass;
    this.transcriptLocationReadyClass = transcriptLocationReadyClass;
    this.transcriptLocationLoadingClass = transcriptLocationLoadingClass;
    this.transcriptLocationNewTabClass = transcriptLocationNewTabClass;
    this.transcriptLocationTimestampClass = transcriptLocationTimestampClass;
    this.transcriptLocationPreviewClass = transcriptLocationPreviewClass;
    this.transcriptLocationSelectedClass = transcriptLocationSelectedClass;

    this.players = players;
    this.patreonRss = patreonRss;
    this.historyManager = historyManager;
    this.formatTime = formatTime;

    this.locationStatuses = [
      transcriptLocationReadyClass,
      transcriptLocationLoadingClass,
      transcriptLocationNewTabClass
    ];

    this.playingItem = null;
  }

  renderSearchedTextRegex() {
    let searchedTextRegex = this.search.value.toLowerCase();
    searchedTextRegex = searchedTextRegex.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    searchedTextRegex = "(" + searchedTextRegex.replaceAll("i", "[iI]") + ")";
    searchedTextRegex = new RegExp(searchedTextRegex);

    return searchedTextRegex;
  }

  replacePlayingItem(transcriptLocation) {
    if (this.playingItem) {
      this.playingItem.classList.remove(this.transcriptLocationSelectedClass);
    }

    if (transcriptLocation) {
      this.playingItem = transcriptLocation;
      this.playingItem.classList.add(this.transcriptLocationSelectedClass);
    }
  }

  playResult = (event) => {
    const contentType = event.target.closest("[data-content-type]").dataset.contentType;
    const remoteId = event.target.closest("[data-remote-id]").dataset.remoteId;
    const mediaUrl = event.target.closest("[data-media-url]").dataset.mediaUrl;
    const contentItem = event.target.closest("." + this.contentItemClass);
    const title = contentItem.getElementsByClassName(this.contentItemLinkTextClass)[0].innerHTML;

    const transcriptLocation = event.target.closest("." + this.transcriptLocationClass);
    const seconds = transcriptLocation ? transcriptLocation.dataset.seconds : 0;

    if (contentType == "patreon" && !this.patreonRss.mediaUrlForRemoteId(remoteId)) {
      const url = this.players.link(contentType, remoteId, seconds);

      window.open(url, "_blank");
    } else {
      this.replacePlayingItem(transcriptLocation);
      this.historyManager.setHistory(null, false, true, false, contentType, remoteId, seconds);

      this.players.cue(contentType, remoteId, mediaUrl, title, seconds, true);
    }

    return false;
  }

  addResults(results, selectedContentType, selectedRemoteId, selectedSeconds) {
    this.contentItems.innerHTML = "";

    const searchedTextRegex = this.renderSearchedTextRegex();

    for (const result of results) {
      this.addResult(result, searchedTextRegex, selectedContentType, selectedRemoteId, selectedSeconds);
    }
  }

  addResult(result, searchedTextRegex, selectedContentType, selectedRemoteId, selectedSeconds) {
    const newResult = this.contentItemTemplate.cloneNode(true);
    newResult.removeAttribute("id");

    const resultDiv = newResult.getElementsByClassName(this.contentItemClass)[0];
    resultDiv.dataset.contentType = result.content_type;
    resultDiv.dataset.remoteId = result.remote_id;

    if (result.media_url) {
      resultDiv.dataset.mediaUrl = result.media_url;
    }

    const videoLink = newResult.getElementsByClassName(this.contentItemLinkClass)[0];

    const videoLinkText = videoLink.getElementsByClassName(this.contentItemLinkTextClass)[0];
    videoLinkText.innerHTML = result.title;

    const videoLinkIcon = videoLink.getElementsByClassName(result.content_type + "-link-icon")[0];
    videoLinkIcon.hidden = false;

    videoLink.href = this.players.link(result.content_type, result.remote_id, null);
    videoLink.onclick = this.playResult;

    if (
      result.content_type == "patreon" &&
      this.patreonRss.metadata &&
      !this.patreonRss.metadata.urls[result.remote_id]
    ) {
      this.patreonRss.updateRss(true, false, false);
    }

    const videoDate = newResult.getElementsByClassName(this.contentItemUploadDateClass)[0];
    const date = new Date(result.uploaded_at);
    const zoneAbbreviation = date.toLocaleTimeString("en-US", { timeZoneName: "short" }).split(" ")[2];
    const dateFormatted = date.toLocaleString("sv") + " " + zoneAbbreviation;
    videoDate.innerHTML = dateFormatted.substr(0, 10);
    videoDate.title = dateFormatted;

    const transcriptLocations = newResult.getElementsByClassName(this.transcriptLocationsClass)[0];
    const originalTranscriptLocation = transcriptLocations.getElementsByClassName(this.transcriptLocationClass)[0];
    transcriptLocations.innerHTML = "";

    for (const timestamp of result.timestamps) {
      const transcriptLocation = originalTranscriptLocation.cloneNode(true);
      transcriptLocation.dataset.seconds = timestamp.seconds;
      transcriptLocation.onclick = this.playResult;

      if (result.content_type == "patreon") {
        if (!this.patreonRss.enabled) {
          this.setTranscriptLocationStatus(transcriptLocation, this.transcriptLocationNewTabClass);
        } else if (!this.patreonRss.mediaUrlForRemoteId(result.remote_id)) {
          this.setTranscriptLocationStatus(transcriptLocation, this.transcriptLocationLoadingClass);
        }
      }

      const link = transcriptLocation.getElementsByClassName(this.transcriptLocationTimestampClass)[0];
      link.innerHTML = this.formatTime(timestamp.seconds);
      link.href = this.players.link(result.content_type, result.remote_id, timestamp.seconds);

      let formattedPreview = timestamp.preview.replace(searchedTextRegex, "<p>$1</p>");

      if (timestamp.prefix != "") {
        formattedPreview = timestamp.prefix + " " + formattedPreview;
      }

      transcriptLocation.getElementsByClassName(this.transcriptLocationPreviewClass)[0].innerHTML = formattedPreview;

      if (
        result.content_type == selectedContentType &&
        result.remote_id == selectedRemoteId &&
        timestamp.seconds == selectedSeconds
      ) {
        this.replacePlayingItem(transcriptLocation);
      }

      transcriptLocations.appendChild(transcriptLocation);
    }

    this.contentItems.appendChild(newResult);
  }

  updatePatreonLocationStatuses() {
    const patreonResults = this.contentItems.querySelectorAll(
      "." + this.contentItemClass + "[data-content-type='patreon']"
    );

    for (const patreonResult of patreonResults) {
      const remoteId = patreonResult.dataset.remoteId;

      let visibleStatus;

      if (!this.patreonRss.enabled) {
        visibleStatus = this.transcriptLocationNewTabClass;
      } else if (!this.patreonRss.mediaUrlForRemoteId(remoteId)) {
        visibleStatus = this.transcriptLocationLoadingClass;
      } else {
        visibleStatus = this.transcriptLocationReadyClass;
      }

      const transcriptLocations = patreonResult.getElementsByClassName(this.transcriptLocationClass);

      for (const transcriptLocation of transcriptLocations) {
        this.setTranscriptLocationStatus(transcriptLocation, visibleStatus);
      }
    }
  }

  setTranscriptLocationStatus(transcriptLocation, visibleStatus) {
    for (const locationStatus of this.locationStatuses) {
      transcriptLocation.getElementsByClassName(locationStatus)[0].hidden = locationStatus !== visibleStatus;
    }
  }
}

export default ResultsBuilder;
