class UpsideDown {
  constructor({ selectors, upsideDownClass }) {
    this.selectors = selectors;
    this.upsideDownClass = upsideDownClass;
  }

  isAprilFools() {
    var date = new Date();

    return date.getMonth() == 3 && date.getDate() == 1;
  }

  flip() {
    for (const selector of this.selectors) {
      const element = document.querySelector(selector);

      element.classList.add(this.upsideDownClass);
    }
  }

  flipIfAprilFools() {
    if (this.isAprilFools()) {
      this.flip();
    }
  }
}

export default UpsideDown;
