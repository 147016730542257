class SummaryBuilder {
  constructor({ summary, patreonCheckbox }) {
    this.summary = document.querySelector(summary);
    this.patreonCheckbox = document.querySelector(patreonCheckbox);

    this.sums;
  }

  totalsSummary() {
    let words = this.sums.not_patreon_words;
    let seconds = this.sums.not_patreon_seconds;

    if (this.patreonCheckbox.checked) {
      words += this.sums.patreon_words;
      seconds += this.sums.patreon_seconds;
    }

    const wordsLocale = words.toLocaleString("en-US");
    const hours = Math.floor(seconds / 3600).toLocaleString("en-US");

    const notPatreonSum = this.sums.not_patreon_sum.toLocaleString("en-US");
    const patreonSum = this.sums.patreon_sum.toLocaleString("en-US");

    let summaryText = "Searching " + wordsLocale + " words in " + hours + " hours of " + notPatreonSum + " videos";

    if (this.patreonCheckbox.checked) {
      summaryText += " and " + patreonSum + " Patreon posts";
    }

    this.summary.innerHTML = summaryText;
  }

  resultsSummary(results) {
    let notPatreonSum = 0;
    let patreonSum = 0;
    let quoteSum = 0;

    for (const result of results) {
      result.content_type == "patreon" ? patreonSum++ : notPatreonSum++;
      quoteSum += result.timestamps.length;
    }

    if (!notPatreonSum && !patreonSum) {
      this.summary.innerHTML = "You were outbid on your search and found no quotes";

      return;
    }

    if (quoteSum == 1) {
      this.summary.innerHTML = "Found one quote like a real hobo";

      return;
    }

    if (quoteSum == 1000) {
      this.summary.innerHTML = "You won the shopping spree with more than 1000 quotes";

      return;
    }

    const videoPlural = notPatreonSum == 1 ? "" : "s";
    const patreonPlural = patreonSum == 1 ? "" : "s";
    const quotePlural = quoteSum == 1 ? "" : "s";

    let videosAndPatreon = "";

    if (notPatreonSum) {
      videosAndPatreon += notPatreonSum + " stupid video" + videoPlural;
    }

    if (patreonSum) {
      if (videosAndPatreon) {
        videosAndPatreon += " and "
      }

      videosAndPatreon += patreonSum + " Patreon post" + patreonPlural;
    }

    this.summary.innerHTML = "Found " + quoteSum + " quote" + quotePlural + " in " + videosAndPatreon;
  }

  errorSummary() {
    this.summary.innerHTML = "The server responded by giving you snake eyes";
  }
}

export default SummaryBuilder;
