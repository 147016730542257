class YoutubePlayer {
  constructor({ playerId, hiddenClass }) {
    this.playerId = playerId;
    this.hiddenClass = hiddenClass;

    this.youtubePlayer = null;

    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";

    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  }

  create(remoteId, seconds, startPlayback) {
    if (this.youtubePlayer) {
      return false;
    }

    this.youtubePlayer = new YT.Player(this.playerId, {
      videoId: remoteId,
      playerVars: {
        start: seconds,
        autoplay: startPlayback
      },
      events: {
        "onReady": function(event) {
          event.target.unMute();
          event.target.setVolume(100);
        }
      }
    });

    return true;
  }

  pause() {
    try {
      this.youtubePlayer.pauseVideo();
    } catch(e) {}
  }

  isPaused() {
    try {
      const state = this.youtubePlayer.getPlayerState()

      if (state == 1 || state == 3) {
        return false;
      }
    } catch(e) {}

    return true;
  }

  hide() {
    document.getElementById(this.playerId).classList.add(this.hiddenClass);
  }

  show() {
    document.getElementById(this.playerId).classList.remove(this.hiddenClass);
  }

  cue(remoteId, _mediaUrl, _title, seconds, startPlayback) {
    try {
      if (startPlayback) {
        if (this.youtubePlayer.getVideoData().video_id == remoteId) {
          this.youtubePlayer.seekTo(seconds);
          this.youtubePlayer.playVideo();
        } else {
          this.youtubePlayer.loadVideoById(remoteId, seconds);
        }
      } else {
        this.youtubePlayer.cueVideoById(remoteId, seconds);
      }
    } catch(e) {}
  }

  link(remoteId, seconds) {
    let link = "https://www.youtube.com/watch?v=" + remoteId;

    if (seconds) {
      link += "&t=";

      let secondsRemainder = seconds;

      const hours = Math.floor(secondsRemainder / 3600);
      secondsRemainder -= hours * 3600;

      const minutes = Math.floor(secondsRemainder / 60);
      secondsRemainder -= minutes * 60;

      if (hours) {
        link += hours + "h";
      }

      if (minutes) {
        link += minutes + "m";
      }

      link += secondsRemainder + "s";
    }

    return link;
  }
}

export default YoutubePlayer;
